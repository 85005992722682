.project-heading {
    text-align: center;
    padding: 4rem 0 2rem 0;
}
.project-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}
.project-card {
    background: #000000;
    color: white;
    border-radius: 12px;
    padding: 1.2rem 1rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s;
}

.wider-card {
    grid-column: span 2; /* Make the wider card span 2 columns */
}
.project-card:hover {
    transform: translateY(-25px);
}

.project-card img{
    width: 100%;
    border-radius: 10px;
}
.project-title{
    color:#fff;
    padding:  1rem 0;
}
.pro-details{
    padding-bottom: 0rem;
    font-size: 1.1rem;
    text-align: justify;
    word-spacing: 0.1px; 
}

.pro-btns{
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0; 
}
.pro-btns .btn{
    padding: 0.5rem 1rem;
    border-radius: 3px;
}
@media screen and (max-width:740px){
    .project-container{
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
      
    }
}