.hero-img {
  width: 100%;
  height: 100vh;
  position: relative;
}

.image-src {
  position: absolute;
  top: 10px; /* Adjust the top position based on your layout */
  left: 10px; /* Adjust the left position based on your layout */
  color: white;
  font-size: 14px;
  padding: 5px;
  z-index: 1; /* Ensure that it's above the image */
}

.social-img {
  position: absolute;
  top: 560px;
  left: 5px;
  transform: translateX(-50%);
  z-index: -1;
}

.hero-img::before {
  content: "";
  background-size: cover;
  background-position: center top;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  z-index: 0;
}

button {
  padding: 13px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #403004;
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-img h1 {
  font-size: 2.4rem;
  color: white; /* Set text color to white */
}

.hero-img p {
  font-size: 1.4rem;
  text-align: center;
  color: white; /* Set text color to white */
}


/* ... Your existing styles ... */

@media screen and (max-width: 640px) {
  .hero-img h1 {
      font-size: 2rem;
  }
  .hero-img p {
      font-size: 1rem;
      line-height: 1.5;
      white-space: normal;
  }
}