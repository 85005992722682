@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

.buton2, .buton3 {
  padding: 15px 25px; /* Buton boyutunu büyüttük */
  font-size: 1.5rem; /* Yazı boyutunu artırdık */
  border-radius: 8px; /* Daha yuvarlatılmış köşeler */
  margin-bottom: 15px; /* Butonlar arasındaki boşluğu artırdık */

  /* Ortak Stiller */
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.buton2 {
  background: linear-gradient(90deg, #003366, #001f4d);
  color: #fff;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.buton2:hover {
  background: linear-gradient(90deg, #001f4d, #003366);
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 31, 77, 0.2), 0 4px 8px rgba(0, 31, 77, 0.1);
}

.buton3 {
  background: linear-gradient(90deg, #2d2d2d, #000000);
  color: #ffffff;
  font-weight: 700;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.1);
}

.buton3:hover {
  background: linear-gradient(90deg, #000000, #2d2d2d);
  transform: scale(1.08);
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.4), 0 5px 10px rgba(0, 0, 0, 0.3);
}

/* Genel Stiller */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

body {
  background: #000;
  color: #fff;
}

h1, h4, p, a {
  color: #ffff;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 15px 30px;
  font-size: 1.2rem;
  text-transform: uppercase;
  background: rgb(248, 217, 15);
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  background: transparent;
  color: #fff;
}

.btn:hover {
  background: rgb(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.35s;
}
